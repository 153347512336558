/* carousel.css */

/* Customize the carousel container */

.h-full {
  height: 99% !important;
}

.carousel {
  /* Add your custom styles here */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  border: none;

  .control-arrow {
    /* Add your custom styles here */
    color: black;
    font-size: 24px;
    background-color: white !important;

    &:before {
      color: black !important;
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    &.control-next {
      &:before {
        content: '>' !important;
      }
    }

    &.control-prev {
      &:before {
        content: '<' !important;
      }
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .control-dots {
    position: relative;
  }

  .slide {
    /* Add your custom styles here */
    text-align: left;
  }
}
